import * as React from "react"
import '../styles/global.scss'

export default function IndexPage() {


  return (
    <main>
          <p>Ye</p>
    </main>
  )
};
